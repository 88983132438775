.stylesheet-a4 {
    display: block;
    page-break-after: always;
    break-after: always;
}

.stylesheet-a4__container {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
}

.payment-card {
    border: 1px solid #000;
    border-radius: 2px;
    display: inline-block;
}

.payment-card:nth-child(even) {
    margin-left: auto;
}

.payment-card__container {

    width: 8.5cm;
    max-width: 8.5cm;
    height: 12.27cm;
    max-height: 12.27cm;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    position: relative;
}

.payment-card p,
.payment-card span,
.payment-card strong {
    font-size: 10px !important;
    line-height: 1.5 !important;
}


p.payment-card__id {
    text-align: center;
    font-size: 12px !important;
}

.payment-card p {
    margin: 0;
    line-height: 18px;
}

.payment-card__personal {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}

.payment-card__comments {
    display: flex !important;
    flex-direction: column;
    gap: 4px;
}

.payment-card__payments {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}

.payment-card__info p strong {
    display: inline-block !important;
    width: 80px;
}

.payment-card__id strong,
.payment-card__personal p strong,
.payment-card__payments p strong {
    margin-right: 8px;
}

span.payment-card__observation {
    text-align: center;
    font-size: 12px !important;
}

.payment-card__top {
    display: flex !important;
    flex-direction: column;
    gap: 8px;
}

table.payment-card__table {
    width: 100%;
    border-collapse: collapse;
}

table.payment-card__table tbody tr td {
    border: 1px solid #000;
    height: 35px;
    width: 25%;
}


@media print {

    @page {
        margin: 0.5cm;
    }

    * {
        color: #000000de;
        font-style: normal;
        font-family: PoppinsSemiBold;
        font-weight: 400;
    }

    .stylesheet-a4 {
        display: block;
        page-break-after: always;
        break-after: always;

    }

    .stylesheet-a4__container {
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
    }

    .payment-card__container {
        width: 8.5cm;
        max-width: 8.5cm;
        height: 12.27cm;
        max-height: 12.27cm;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;
        position: relative;
    }

    /* .payment-card:nth-child(even) {
        margin-left: auto;
        float: right;
    } */

    /* .payment-card:first-child {
        margin-bottom: 9mm;
    } */
    /*     
    .payment-card:nth-child(4n+1):not(:first-child) {
        break-before: always;
        page-break-before: always; 
    } */
 
    .payment-card:nth-child(even) {
        margin-left: auto;
    }

    .payment-card:nth-child(4n+1) { 
        margin-bottom: 6mm;
        /* outline: 1px solid rgb(0, 89, 255); */
    }

    .payment-card:nth-child(4n+2) {
        margin-bottom: 6mm;
        /* outline: 1px solid rgb(24, 207, 79); */
    }

    /* .payment-card:nth-child(4n+3) {
        outline: 1px solid red;
    }

    .payment-card:nth-child(4n+4) {
        outline: 1px solid rgb(255, 0, 200);
    } */
}