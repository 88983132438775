@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsExtraBold";
  src: local("PoppinsExtraBold"),
    url("./assets/fonts/Poppins-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratExtraBold";
  src: local("MontserratExtraBold"),
    url("./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratLight";
  src: local("MontserratLight"),
    url("./assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("./assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
    url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratSemibold";
  src: local("MontserratSemibold"),
    url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
